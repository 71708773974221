import { React, useEffect, useState } from "react";
import { Link as LinkRouter } from "react-router-dom";
import axios from "axios";
// Materialize stuff
import { Icon } from "react-materialize";
// Own stuff
import { unEscape, copyToClipBoard } from "../Utils";
import { isStaff } from "../Auth";
import { baseURL } from "../endpoints";
import { ValidateIPaddress } from "./Utilities";

const ax = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  headers: { "Access-Control-Allow-Origin": "*" },
});

function Ban(props) {
  const ban_string = `${props.ip}: ${props.original_reason}`;
  return (
    <div
      id={props.ip + "_" + props.id}
      className="card-panel indigo darken-1 white-text hoverable command"
    >
      <h5 className="center white-text" style={{ margin: 0 }}>
        <b>IGN:</b> {props.ign}
      </h5>
      <p>
        <b>Offense:</b> {props.reason}
      </p>
      <p>
        <b>Server:</b> {unEscape(props.server)}
      </p>
      <p>
        <b>Ban string:</b>{" "}
        <button
          className="btn-floating btn-small grey lighten-2 right"
          onClick={() => copyToClipBoard("code-" + props.ip + "_" + props.id)}
        >
          <Icon className="black-text">content_copy</Icon>
        </button>
        <code id={"code-" + props.ip + "_" + props.id}>
          {unEscape(ban_string)}
        </code>
      </p>
    </div>
  );
}

export function Bans() {
  const [ipSearchText, setIpSearchText] = useState("");
  const [validIP, setValidIP] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [banData, setBanData] = useState(null);

  useEffect(() => {
    if (ipSearchText.length === 0) {
      setValidIP(null);
      return;
    }
    setValidIP(ValidateIPaddress(ipSearchText));
  }, [ipSearchText]);

  const filterSearch = () => {
    if (!ipSearchText) {
      return;
    }
    setIsError(false);
    setIsLoading(true);
    ax.get(`/bans/${ipSearchText}`)
      .then((res) => {
        if (res.data.data) {
          console.log(res.data.data);
          setBanData(res.data.data);
        } else {
          console.log("No ban found");
          setBanData(null);
        }
      })
      .catch((error) => {
        setIsError(true);
        console.log("error", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (!isStaff()) {
    window.location = "/login?next=bans";
    return <></>;
  }
  if (isError) {
    return (
      <div className="container" style={{ padding: "0 1em 1em 1em" }}>
        <LinkRouter className="btn-floating blue white-text left" to="/docs">
          <i className="material-icons">arrow_back</i>
        </LinkRouter>
        <h3 className="center green-text">Bans</h3>
        <div className="center">
          <h5>Woops, something went wrong</h5>
        </div>
      </div>
    );
  }
  return (
    <div className="container">
      <LinkRouter className="btn-floating blue white-text left" to="/docs">
        <i className="material-icons">arrow_back</i>
      </LinkRouter>
      <h3 className="center green-text">Bans</h3>
      <div className="row">
        <h5 className="center">Filter</h5>
        <div className="col s12 input-field">
          <input
            placeholder="192.168.1.1"
            id="input_ip"
            type="text"
            className="validate"
            onChange={(e) => setIpSearchText(e.target.value)}
          />
          <label htmlFor="input_ip">IP</label>
        </div>
        {validIP != null && (
          <div
            style={{ display: "flex", alignItems: "center" }}
            className={validIP ? "green-text" : "red-text"}
          >
            {validIP && <Icon className="green-text small">check_circle</Icon>}
            {!validIP && <Icon className="red-text small">error</Icon>}
            <span>IP is: {validIP ? "valid" : "invalid"}</span>
          </div>
        )}
        <div className="col s12">
          <button
            className="btn blue"
            onClick={() => {
              filterSearch();
            }}
          >
            Filter
          </button>
        </div>
      </div>
      {isLoading && (
        <div className="center">
          <div className="row"></div>
          <div className="preloader-wrapper big active">
            <div className="spinner-layer spinner-blue-only">
              <div className="circle-clipper left">
                <div className="circle"></div>
              </div>
              <div className="gap-patch">
                <div className="circle"></div>
              </div>
              <div className="circle-clipper right">
                <div className="circle"></div>
              </div>
            </div>
          </div>
        </div>
      )}

      {banData && <Ban id={1} {...banData} />}
    </div>
  );
}
