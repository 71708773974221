import { React, Component } from "react";
import axios from "axios";
// CSS
// Components
import { Row } from "react-materialize";
import { clearSession } from "./Auth";
// logout endpoint
import { logoutEndpoint } from "./endpoints";

export default class SignOut extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    axios
      .get(logoutEndpoint, { withCredentials: true })
      .then((res) => {
        // console.log(res.data);
        if (res.data.status) {
          clearSession();
          setTimeout(() => {
            window.location = "/login";
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div className="container center">
        <Row>
          <h2>Login out...</h2>
        </Row>
      </div>
    );
  }
}
