import { React, Component } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import M from "materialize-css";

import { mwByHandled, mwBySubmitters } from "../../endpoints";
import { axiosClient } from "../../generic/Axios";

export class MWChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      totalKey: props.totalKey,
      approvedKey: props.approvedKey,
    };
  }
  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={this.state.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey={this.state.totalKey} fill="#2e86c1" />
          <Bar dataKey={this.state.approvedKey} fill="#48c9b0" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export class MWPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      submittedData: null,
      handledData: null,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      M.AutoInit();
    }, 50);
    this.setState({
      isLoading: true,
    });
    axiosClient
      .get(mwBySubmitters, { withCredentials: true })
      .then((r) => {
        if (r.status === 200) {
          this.setState({
            submittedData: r.data.data,
          });
        }
      })
      .finally(() => {
        axiosClient
          .get(mwByHandled, { withCredentials: true })
          .then((r) => {
            if (r.status === 200) {
              this.setState({
                handledData: r.data.data,
              });
            }
          })
          .finally(() => {
            this.setState({
              isLoading: false,
            });
          });
      });
  }

  render() {
    return (
      <div className="row">
        <h5 className="center">MW stats</h5>
        {this.state.isLoading && (
          <div className="center">
            <div className="row"></div>
            <div className="preloader-wrapper big active">
              <div className="spinner-layer spinner-blue-only">
                <div className="circle-clipper left">
                  <div className="circle"></div>
                </div>
                <div className="gap-patch">
                  <div className="circle"></div>
                </div>
                <div className="circle-clipper right">
                  <div className="circle"></div>
                </div>
              </div>
            </div>
          </div>
        )}

        {!this.state.isLoading && this.state.submittedData && (
          <div style={{ height: "600px" }}>
            <h4>Submitted by</h4>
            <div className="col s12 m8 offset-m2" style={{ height: "500px" }}>
              <MWChart
                data={this.state.submittedData}
                totalKey="total"
                approvedKey="approved"
              />
            </div>
          </div>
        )}
        {!this.state.isLoading && this.state.handledData && (
          <div style={{ height: "600px" }}>
            <h4>Handled by</h4>
            <div className="col s12 m8 offset-m2" style={{ height: "500px" }}>
              <MWChart
                data={this.state.handledData}
                totalKey="total"
                approvedKey="approved"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
