import { React, Fragment, Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import M from "materialize-css";
import { ReactSession } from "react-client-session";
import axios from "axios";
// CSS
import "materialize-css/dist/css/materialize.min.css";
import "./css/App.css";
// Components
import Home from "./components/Home";
import SignOut from "./SignOut";
import Dashboard from "./components/Dashboard";
import {
  DocumentationLayout,
  Documentation,
  Commands,
  AosCommands,
  SysCommands,
  API,
} from "./components/docs/Docs";
import { Emojis } from "./components/docs/Emojis";
import { FilesLayout, EmptyFile, AppFile } from "./components/Files";
import Login from "./Login";
import ResponsiveNavBar from "./NavBar";
import { clearSession, isLoggedIn, isStaff, setCredentials } from "./Auth";
import {
  Features,
  Echos,
  Aliases,
  GeoIPSearch,
} from "./components/features/Features";
import Tools from "./components/Tools";
import NotFound from "./components/NotFound";
import { Bans } from "./components/Bans";
import { loginEndpoint } from "./endpoints";
import BotLive from "./components/live/BotLive";
import { MWPage } from "./components/features/MW";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    ReactSession.setStoreType("sessionStorage");
  }
  componentDidMount() {
    M.AutoInit();
    if (!isLoggedIn()) {
      axios
        .get(loginEndpoint, { withCredentials: true })
        .then((res) => {
          if (res.status === 200) {
            setCredentials(res.data.username, res.data.status, res.data.roles);
            this.forceUpdate();
          }
        })
        .catch((err) => {});
    }
  }
  render() {
    if (isLoggedIn() && !isStaff()) {
      clearSession();
      return (
        <>
          <h1>Not allowed</h1>
          <a href="/login">Login</a>
        </>
      );
    }
    return (
      <Fragment>
        <Router>
          <ResponsiveNavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/features/*" element={<Features />}>
              <Route path="echos" element={<Echos />} />
              <Route path="aliases" element={<Aliases />} />
              <Route path="geoip" element={<GeoIPSearch />} />
              <Route path="mw" element={<MWPage />} />
            </Route>
            <Route path="/tools" element={<Tools />} />
            <Route path="/bans" element={<Bans />} />
            <Route path="/docs/*" element={<DocumentationLayout />}>
              <Route index element={<Documentation />} />
              <Route path="commands" element={<Commands />} />
              <Route path="emojis" element={<Emojis />} />
              <Route path="aoscommands" element={<AosCommands />} />
              <Route path="echos" element={<Echos />} />
              <Route path="syscommands" element={<SysCommands />} />
              <Route path="api" element={<API />} />
            </Route>
            <Route path="/files/*" element={<FilesLayout />}>
              <Route index element={<EmptyFile />} />
              <Route path=":id" element={<AppFile />} />
            </Route>

            <Route path="live" element={<BotLive />} />

            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<SignOut />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </Fragment>
    );
  }
}

export default App;
