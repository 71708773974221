import axios from "axios";
import React, { Component, useState, useEffect } from "react";
import { Outlet, Link as LinkRouter, useParams } from "react-router-dom";
// CSS
import "../css/Files.css";
// Components
import M from "materialize-css";
// import { Icon } from "react-materialize";
import { ReactSession } from "react-client-session";
import { isStaff } from "../Auth";
// import NotAllowed from "./NotAllowed";
import { baseURL } from "../endpoints";
import { unEscape, copyToClipBoard } from "../Utils";

// axios instance
axios.defaults.withCredentials = true;

const ax = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  headers: { "Access-Control-Allow-Origin": "*" },
});

function AppFile() {
  let params = useParams();
  const [fileId, setFileId] = useState(params.id);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [contentData, setContentData] = useState();

  // console.log(params);
  // console.log(fileId);
  //   constructor(props) {
  //     super(props);
  //     console.log(props);
  //     this.state = {
  //       //   fileId: props.match.params.id,
  //       fileId: "props.match.params.id",
  //       isLoading: true,
  //       isError: false,
  //       contentData: null,
  //     };
  //   }

  // componentDidMount() {
  //   ax.get(`/files/${this.state.fileId}`, {
  //     withCredentials: true,
  //   })
  //     .then((response) => {
  //       console.log(response.data);
  //       if (response.data.length > 0) {
  //         const reader = new FileReader();
  //         reader.onload = async (e) => {
  //           this.setState({
  //             contentData: e.target.result,
  //           });
  //         };
  //         reader.readAsText(response.data);
  //         this.setState({
  //           isLoading: false,
  //           isError: false,
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       this.setState({
  //         isError: false,
  //       });
  //     });
  // }
  // render() {
  useEffect(() => {
    ax.get(`/files/` + fileId, {
      withCredentials: true,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.length > 0) {
          setContentData(response.data);
          // const reader = new FileReader();
          // reader.onload = async (e) => {
          //   setContentData(e.target.result);
          // };
          // reader.readAsText(response.data);
          setIsLoading(false);
          setIsError(false);
        }
      })
      .catch((error) => {
        setIsError(true);
      });
  }, []);

  if (!fileId) {
    window.location = "/files";
    return <></>;
  }
  if (!isStaff()) {
    window.location = "/login?next=files/" + fileId;
    return <></>;
  }
  if (isError) {
    return (
      <div className="row">
        <h3 className="center green-text">File {fileId && fileId}</h3>
        <div className="center">
          <h5>Woops, something went wrong</h5>
        </div>
      </div>
    );
  }
  return (
    <div className="row">
      {isLoading && (
        <div className="center">
          <div className="row"></div>
          <div className="preloader-wrapper big active">
            <div className="spinner-layer spinner-blue-only">
              <div className="circle-clipper left">
                <div className="circle"></div>
              </div>
              <div className="gap-patch">
                <div className="circle"></div>
              </div>
              <div className="circle-clipper right">
                <div className="circle"></div>
              </div>
            </div>
          </div>
        </div>
      )}
      <h5 className="center green-text">File {fileId && fileId}</h5>
      {contentData && (
        <div className="col s12 black white-text">
          <pre>{unEscape(contentData)}</pre>
        </div>
      )}
    </div>
  );
}

class FilesLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    if (!isStaff()) {
      window.location = "/login?next=files";
      return <></>;
    }
    return (
      <div className="container">
        <h1 className="center">CheckBot</h1>
        <h5 className="center">The bot that you love</h5>
        <div className="row">
          <p>Hello {ReactSession.get("username")}</p>
        </div>
        <Outlet />
      </div>
    );
  }
}

class EmptyFile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    setTimeout(() => {
      M.AutoInit();
    }, 50);
  }

  render() {
    if (!isStaff()) {
      window.location = "/login?next=files";
      return <></>;
    }
    return (
      <div className="row">
        <h4 className="center">File not found</h4>
        <p>The link you copied is not correct or the file was deleted</p>
      </div>
    );
  }
}

export { AppFile, EmptyFile, FilesLayout };
