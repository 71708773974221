import { React, Component } from "react";
import { Icon } from "react-materialize";

export class BackTopButtonComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  scrollFunction = () => {
    if (
      document.body.scrollTop > 500 ||
      document.documentElement.scrollTop > 500
    ) {
      document.getElementById("buttonTop").style.display = "block";
    } else {
      document.getElementById("buttonTop").style.display = null;
    }
  };
  componentDidMount() {
    window.addEventListener("scroll", this.scrollFunction);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollFunction);
  }
  buttonBackOnClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    document.getElementById("buttonTop").style.display = null;
  };
  render() {
    return (
      <button
        id="buttonTop"
        className="button-back btn-floating"
        style={{ display: "none" }}
        onClick={this.buttonBackOnClick}
      >
        <Icon>arrow_upward</Icon>
      </button>
    );
  }
}
