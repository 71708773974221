import React, { Component } from "react";
import { isStaff } from "../../Auth";
import M from "materialize-css";

import "../../css/live/BotLive.css";

const ChatLine = ({ text }) => {
  return <pre className="chat-line">{text}</pre>;
};

export default class BotLive extends Component {
  componentDidMount() {
    setTimeout(() => {
      M.AutoInit();
    }, 50);
  }

  render() {
    if (!isStaff()) {
      window.location = "/login?next=/live";
      return <></>;
    }
    return (
      <div className="container">
        <p className="center">WIP</p>
        <div className="terminal grey white-text">
          <ChatLine text={"Some text here"} />
        </div>
        <div className="row input-field">
          <input type="text" name="user-input" />
          <label htmlFor="user-input">Your input</label>
        </div>
      </div>
    );
  }
}
