import { React, useState, useEffect } from "react";
import { Link as LinkRouter } from "react-router-dom";
import {
  Icon,
  Button,
  Modal,
  TextInput,
  Select,
  Divider,
} from "react-materialize";

import { jump } from "../../generic/jump";
import { isStaff } from "../../Auth";
import { axiosClient } from "../../generic/Axios";

export function CreateEmoji(props) {
  const [emojiText, setEmojiText] = useState("");
  const [emojiData, setEmojiData] = useState("");
  const [emojiImageType, setEmojiImageType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const checkEmoji = () => {
    if (isLoading || emojiText.length === 0) return;
    const e = props.emojis.find(
      (elem) => elem.id === emojiText && elem.available === true
    );
    if (e) {
      setIsLoading(true);
      setEmojiImageType(e.animated ? "gif" : "jpeg");
      axiosClient
        .get("/emojis/" + e.id, {
          params: { animated: e.animated },
          withCredentials: true,
        })
        .then((response) => {
          setEmojiData(response.data.data);
        })
        .catch((error) => {
          console.log(error);
          setEmojiData("");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  useEffect(checkEmoji, [emojiText]);

  const submitData = () => {
    console.log("Submit!");
    props.close();
  };
  return (
    <Modal
      actions={[
        <Button
          className="red"
          onClick={() => props.close()}
          modal="close"
          node="button"
          waves="green"
        >
          Cancel
        </Button>,
        <Button
          style={{ marginLeft: "0.25em" }}
          onClick={submitData}
          className="green"
          node="button"
          waves="light"
        >
          Create<Icon right>check</Icon>
        </Button>,
      ]}
      bottomSheet={false}
      fixedFooter={false}
      header="Create new emoji (WIP)"
      id="create-emoji-modal"
      open={props.open}
      options={{
        dismissible: false,
        endingTop: "10%",
        inDuration: 250,
        onCloseEnd: null,
        onCloseStart: null,
        onOpenEnd: null,
        onOpenStart: null,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: "4%",
      }}
    >
      <div className="row">
        <Select
          id="emoji-text-input"
          label="Choose your emoji from Aloha server"
          multiple={false}
          s="12"
          m="6"
          options={{
            classes: "offset-m3",
            dropdownOptions: {
              alignment: "left",
              autoTrigger: true,
              closeOnClick: true,
              constrainWidth: true,
              coverTrigger: true,
              hover: false,
              inDuration: 150,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              outDuration: 250,
            },
          }}
          value=""
          disabled={isLoading}
          onChange={(e) => setEmojiText(e.target.value)}
        >
          <option value=""></option>
          {props.emojis &&
            props.emojis.map((e) => {
              return (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              );
            })}
        </Select>
      </div>
      <p>
        Preview:{" "}
        {emojiData && (
          <img
            srcSet={"data:image/" + emojiImageType + ";base64," + emojiData}
            alt="Emoji"
          />
        )}
      </p>
    </Modal>
  );
}

export function Emoji(props) {
  return (
    <div
      id={props.name}
      className="card-panel indigo darken-1 white-text hoverable command"
    >
      <h5 className="center white-text" style={{ margin: 0 }}>
        {props.code}
      </h5>
      <p>
        <b>Command:</b> {props.command}
      </p>
      <p>
        <b>Access:</b> {props.access.name}
      </p>
    </div>
  );
}

export function CreateEmojiTrigger(props) {
  const [pattern, setPattern] = useState("");
  const [validPattern, setValidPattern] = useState(null);
  const [testInput, setTestInput] = useState("");
  const [testSuccess, setTestSuccess] = useState(null);

  const isValidPattern = () => {
    if (pattern.length === 0) {
      setValidPattern(null);
      return;
    }
    try {
      new RegExp(pattern);
      setValidPattern(true);
    } catch (error) {
      setValidPattern(false);
    }
  };
  useEffect(isValidPattern, [pattern]);
  const submitData = () => {
    console.log("Submit!");
    setPattern("");
    setTestInput("");
    props.close();
  };
  const testPattern = () => {
    if (validPattern === null || !validPattern || !testInput) {
      setTestSuccess(null);
      return;
    }
    setTestSuccess(new RegExp(pattern).test(testInput));
  };
  useEffect(testPattern, [testInput, pattern, validPattern]);
  return (
    <Modal
      actions={[
        <Button
          className="red"
          onClick={() => props.close()}
          modal="close"
          node="button"
          waves="green"
        >
          Cancel
        </Button>,
        <Button
          style={{ marginLeft: "0.25em" }}
          onClick={submitData}
          className="green"
          node="button"
          waves="light"
        >
          Create<Icon right>check</Icon>
        </Button>,
      ]}
      bottomSheet={false}
      fixedFooter={false}
      header="Create new emoji trigger (WIP)"
      id="create-emoji-trigger-modal"
      open={props.open}
      options={{
        dismissible: false,
        endingTop: "10%",
        inDuration: 250,
        onCloseEnd: null,
        onCloseStart: null,
        onOpenEnd: null,
        onOpenStart: null,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: "4%",
      }}
    >
      <TextInput
        id="pattern-text-input"
        label="Regular expression"
        value={pattern}
        onChange={(e) => setPattern(e.target.value)}
      />
      {validPattern != null && (
        <div
          style={{ display: "flex", alignItems: "center" }}
          className={validPattern ? "green-text" : "red-text"}
        >
          {validPattern && (
            <Icon className="green-text small">check_circle</Icon>
          )}
          {!validPattern && <Icon className="red-text small">error</Icon>}
          <span>Your pattern is: {validPattern ? "valid" : "invalid"}</span>
        </div>
      )}
      <TextInput
        id="pattern-test-input"
        label="Test regular expression"
        value={testInput}
        onChange={(e) => setTestInput(e.target.value)}
      />
      {testSuccess != null && (
        <div
          style={{ display: "flex", alignItems: "center" }}
          className={testSuccess ? "green-text" : "red-text"}
        >
          {testSuccess && (
            <Icon className="green-text small">check_circle</Icon>
          )}
          {!testSuccess && <Icon className="red-text small">error</Icon>}
          <span>Text {testSuccess ? "matches" : "does not match"}</span>
        </div>
      )}
    </Modal>
  );
}

export function EmojiMessage(props) {
  return (
    <div
      id={props.id}
      className="card-panel indigo darken-1 white-text hoverable command"
    >
      <p>
        <b>Pattern:</b> {props.pattern}
      </p>
      <p>
        <b>Emojis:</b>
        {props.emojis.map((e) => {
          return (
            <button
              className="btn blue"
              key={"btn_" + e}
              onClick={() => jump(e)}
            >
              {e}
            </button>
          );
        })}
      </p>
    </div>
  );
}

export function Emojis() {
  const [emojis, setEmojis] = useState([]);
  const [alohaEmojis, setAlohaEmojis] = useState([]);
  const [emojisMessages, setEmojisMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [createEmoji, setCreateEmoji] = useState(false);
  const [createEmojiTrigger, setCreateEmojiTrigger] = useState(false);
  useEffect(() => {
    // console.log(`${baseURL}command/`);
    axiosClient
      .get(`/emoji/`, {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response.data);
        if (response.data.data.length > 0) {
          setEmojis(response.data.data);
          setIsLoading(false);
          setIsError(false);
        }
      })
      .catch((error) => {
        setIsError(true);
      });
    axiosClient
      .get(`/emoji_message/`, {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response.data);
        if (response.data.data.length > 0) {
          setEmojisMessages(response.data.data);
          setIsLoading(false);
          setIsError(false);
        }
      })
      .catch((error) => {
        setIsError(true);
      });
    axiosClient
      .get("/emojis/", { withCredentials: true })
      .then((response) => {
        if (response.status === 200) {
          setAlohaEmojis(response.data.data);
        }
      })
      .catch((error) => {
        setAlohaEmojis([]);
      });
  }, []);
  if (!isStaff()) {
    window.location = "/login?next=emojis";
    return <></>;
  }
  if (isError) {
    return (
      <div className="container" style={{ padding: "0 1em 1em 1em" }}>
        <LinkRouter className="btn-floating blue white-text left" to="/docs">
          <i className="material-icons">arrow_back</i>
        </LinkRouter>
        <h3 className="center green-text">Emojis</h3>
        <div className="center">
          <h5>Woops, something went wrong</h5>
        </div>
      </div>
    );
  }
  return (
    <div className="container" style={{ padding: "0 1em 1em 1em" }}>
      <LinkRouter className="btn-floating blue white-text left" to="/docs">
        <i className="material-icons">arrow_back</i>
      </LinkRouter>
      <h3 className="center green-text">Emojis</h3>
      {isLoading && (
        <div className="center">
          <div className="row"></div>
          <div className="preloader-wrapper big active">
            <div className="spinner-layer spinner-blue-only">
              <div className="circle-clipper left">
                <div className="circle"></div>
              </div>
              <div className="gap-patch">
                <div className="circle"></div>
              </div>
              <div className="circle-clipper right">
                <div className="circle"></div>
              </div>
            </div>
          </div>
        </div>
      )}
      <p>
        Emojis reaction system. Only available in Aloha's discord server, for
        either #warnings or #watchlist channels
      </p>
      <p>
        Whenever a message matches one of the saved string patterns, the bot
        will automatically add the corresponding emojis to that message. If you
        react to the message with the same emoji, then a command will be used.
        Each emoji is linked to a specific command and once pressed, the emoji
        can not be used again (this is to prevent spam).
      </p>
      <CreateEmoji
        open={createEmoji}
        close={() => setCreateEmoji(false)}
        emojis={alohaEmojis}
      />
      <CreateEmojiTrigger
        open={createEmojiTrigger}
        close={() => setCreateEmojiTrigger(false)}
      />
      <Button
        className="red"
        fab={{
          direction: "top",
          hoverEnabled: false,
        }}
        icon={<Icon>add</Icon>}
        floating
        large
        node="button"
      >
        <Button
          onClick={() => setCreateEmoji(true)}
          className="blue"
          floating
          icon={<Icon>add_reaction</Icon>}
          node="button"
          waves="light"
          tooltip="Create new emoji"
          tooltipOptions={{
            position: "left",
          }}
        />
        <Button
          onClick={() => setCreateEmojiTrigger(true)}
          className="blue"
          floating
          icon={<Icon>notification_add</Icon>}
          node="button"
          waves="light"
          tooltip="Create new emoji trigger"
          tooltipOptions={{
            position: "left",
          }}
        />
      </Button>

      <p>The following are the message patterns and which emojis are linked</p>

      {emojisMessages &&
        emojisMessages.map((cmd) => {
          return <EmojiMessage key={cmd.id} {...cmd} />;
        })}

      <p>The following are each of the available emojis</p>

      {emojis &&
        emojis.map((cmd) => {
          return (
            <button
              className="btn blue"
              key={"btn_" + cmd.name}
              onClick={() => jump(cmd.name)}
            >
              {cmd.name}
            </button>
          );
        })}

      {emojis &&
        emojis.map((cmd) => {
          return <Emoji key={cmd.name} {...cmd} />;
        })}
    </div>
  );
}
