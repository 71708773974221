import M from "materialize-css";

export function unEscape(string) {
  return string
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'")
    .replace(/&#x27;/g, "'")
    .replace(/&amp;/g, "&");
}
export function copyToClipBoard(element) {
  const copyText = document.getElementById(element);
  const elementText = copyText.textContent;

  navigator.clipboard.writeText(elementText);

  M.toast({ html: "Copied!" });
}
