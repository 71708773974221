import { React, Fragment } from "react";
import PropTypes from "prop-types";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
} from "recharts";
// CSS
// Components

// Generate Sales Data
function createData(time, amount) {
  return { time, amount };
}

const data = [
  createData("00:00", 0),
  createData("03:00", 300),
  createData("06:00", 600),
  createData("09:00", 800),
  createData("12:00", 1500),
  createData("15:00", 2000),
  createData("18:00", 2400),
  createData("21:00", 2400),
  createData("24:00", undefined),
];

const data_test = [
  {
    time: "1643096782000",
    value: 3.6,
  },
  {
    time: "1643096787009",
    value: 4.9,
  },
  {
    time: "1643096792023",
    value: 4.9,
  },
  {
    time: "1643096797031",
    value: 4.4,
  },
  {
    time: "1643096802035",
    value: 7.7,
  },
  {
    time: "1643096807040",
    value: 8.6,
  },
  {
    time: "1643096812046",
    value: 4.4,
  },
  {
    time: "1643096817057",
    value: 5.4,
  },
  {
    time: "1643096822068",
    value: 6.9,
  },
  {
    time: "1643096827083",
    value: 8.3,
  },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

export function HorizontalLine(props) {
  return (
    <Fragment>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={props.data}
          width={500}
          height={300}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" stroke="black" style={{ color: "red" }}>
            <Label
              angle={0}
              position="center"
              style={{
                textAnchor: "middle",
                fill: "blue",
              }}
            >
              {props.xLabel}
            </Label>
          </XAxis>
          <YAxis stroke="yellow" style={{ color: "violet" }}>
            <Label
              angle={270}
              position="left"
              style={{
                textAnchor: "middle",
                fill: "green",
              }}
            >
              {props.yLabel}
            </Label>
          </YAxis>
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="value"
            stroke="red"
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </Fragment>
  );
}

HorizontalLine.prototype = {
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
  data: PropTypes.array.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
};

export function PieChartPadding(props) {
  return (
    <PieChart
      width={props.width || 800}
      height={props.height || 400}
      onMouseEnter={props.onMouseEnter}
    >
      <Pie
        data={props.data}
        cx={120}
        cy={200}
        innerRadius={60}
        outerRadius={80}
        fill="#8884d8"
        paddingAngle={5}
        dataKey="value"
      >
        {props.data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
}
