import { ReactSession } from "react-client-session";

export const getUserName = () => {
  return ReactSession.get("username");
};

export const isLoggedIn = () => {
  return ReactSession.get("logged");
};

export const isStaff = () => {
  return (
    ReactSession.get("logged") &&
    ["guard", "moderator", "admin", "sysadmin", "manager", "bk"].includes(
      ReactSession.get("roles")
    )
  );
};

export const isSysAdmin = () => {
  return (
    ReactSession.get("logged") &&
    ["sysadmin", "manager", "bk"].includes(ReactSession.get("roles"))
  );
};

export const clearSession = () => {
  ReactSession.remove("logged");
  ReactSession.remove("username");
  ReactSession.remove("roles");
};

export const setUserName = (username) => {
  ReactSession.set("username", username);
};

export const setIsLoggedIn = (isLogged) => {
  ReactSession.set("logged", isLogged);
};

export const setRoles = (roles) => {
  ReactSession.set("roles", roles);
};

export const setCredentials = (username, isLogged, roles) => {
  setUserName(username);
  setIsLoggedIn(isLogged);
  setRoles(roles);
};
