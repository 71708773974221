import { React, Fragment } from "react";
import { Link as LinkRouter } from "react-router-dom";
// import M from "materialize-css";
// CSS
import "./css/NavBar.css";
// Components
import { Icon, Navbar, Dropdown, Divider } from "react-materialize";
import AlohaLogo from "./media/aloha_logo.png";
import { getUserName, isLoggedIn, isStaff, isSysAdmin } from "./Auth";

const SideNav = () => {
  return (
    <Fragment>
      {isLoggedIn() && (
        <li>
          <p className="center deep-purple darken-1 white-text">
            {getUserName() || "Backend"}
          </p>
          <Divider />
        </li>
      )}
      {!isLoggedIn() && (
        <li>
          <p className="center grey darken-3 white-text">Backend Server</p>
          <Divider />
        </li>
      )}

      <li className="sideNav">
        <LinkRouter className="headerLink" to="/">
          <i className="material-icons">home</i>
          Home
        </LinkRouter>
      </li>
      {isStaff() && (
        <li>
          <LinkRouter className="headerLink" to="/docs">
            Documentation
          </LinkRouter>
        </li>
      )}
      {/* {isSysAdmin() && (
        <li>
          <LinkRouter className="headerLink" to="/dashboard">
            Dashboard
          </LinkRouter>
        </li>
      )} */}
      {isStaff() && (
        <Fragment>
          <li>
            <LinkRouter className="headerLink" to="/features">
              Features
            </LinkRouter>
          </li>
          <li>
            <LinkRouter className="headerLink" to="/tools">
              Tools
            </LinkRouter>
          </li>
          <li>
            <LinkRouter className="headerLink" to="/live">
              Bot Live
            </LinkRouter>
          </li>
        </Fragment>
      )}
      {!isLoggedIn() && (
        <li>
          <LinkRouter className="headerLink" to="/login">
            <i className="material-icons left">login</i>
            Login
          </LinkRouter>
        </li>
      )}
      {isLoggedIn() && (
        <li>
          <LinkRouter className="headerLink" to="/logout">
            <i className="material-icons">logout</i>
            Logout
          </LinkRouter>
        </li>
      )}
    </Fragment>
  );
};

const ResponsiveNavBar = () => {
  return (
    <Navbar
      alignLinks="right"
      brand={
        <a href="https://aloha.pk">
          <img src={AlohaLogo} srcSet={AlohaLogo} alt="Aloha" loading="lazy" />
        </a>
      }
      id="mobile-nav"
      centerChildren
      menuIcon={<Icon>menu</Icon>}
      options={{
        draggable: true,
        edge: "left",
        inDuration: 250,
        onCloseEnd: null,
        onCloseStart: null,
        onOpenEnd: null,
        onOpenStart: null,
        outDuration: 200,
        preventScrolling: true,
      }}
      sidenav={<SideNav />}
    >
      <LinkRouter className="headerLink" to="/">
        Home
      </LinkRouter>
      {isStaff() && (
        <LinkRouter className="headerLink" to="/docs">
          Documentation
        </LinkRouter>
      )}
      {/* {isSysAdmin() && (
        <LinkRouter className="headerLink" to="/dashboard">
          Dashboard
        </LinkRouter>
      )} */}
      {isStaff() && (
        <LinkRouter className="headerLink" to="/features">
          Features
        </LinkRouter>
      )}
      {isStaff() && (
        <LinkRouter className="headerLink" to="/tools">
          Tools
        </LinkRouter>
      )}
      {isStaff() && (
        <LinkRouter className="headerLink" to="/live">
          Bot Live
        </LinkRouter>
      )}
      {!isLoggedIn() && (
        <LinkRouter className="headerLink" to="/login">
          Login
        </LinkRouter>
      )}
      {isLoggedIn() && (
        <Dropdown
          id="dropdown"
          options={{
            alignment: "left",
            autoTrigger: true,
            closeOnClick: true,
            constrainWidth: true,
            container: null,
            coverTrigger: true,
            hover: false,
            inDuration: 150,
            onCloseEnd: null,
            onCloseStart: null,
            onOpenEnd: null,
            onOpenStart: null,
            outDuration: 250,
          }}
          trigger={
            <div className="avatar">
              <img
                className="circle responsive-img"
                src={
                  "https://community-cdn.aloha.pk/user_avatar/aloha.pk/" +
                  getUserName() +
                  "/32/364_2.png"
                }
                alt="avatar"
              />
            </div>
          }
        >
          <p className="center disabled">{getUserName()}</p>
          <Divider />

          <LinkRouter
            className="headerLink blue-text lighten-2 center"
            to="/logout"
          >
            <i
              className="material-icons"
              style={{ margin: "0 auto", width: "100%" }}
            >
              logout
            </i>
            Logout
          </LinkRouter>
        </Dropdown>
      )}
    </Navbar>
  );
};
export default ResponsiveNavBar;
