import { React } from "react";
import { Link as LinkRouter } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="container center">
      <h1>404</h1>
      <p>Page not found</p>
      <LinkRouter className="btn blue white-text" to="/">
        Home
      </LinkRouter>
    </div>
  );
}
