import { React, Component } from "react";
import axios from "axios";
// CSS
// Components
import { HorizontalLine, PieChartPadding } from "../Charts";
import { isSysAdmin } from "../Auth";
import NotAllowed from "./NotAllowed";
// Usages endpoints
import { cpuUsageEndpoint } from "../endpoints";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usagePieData: [],
      usageTimer: null,
    };
  }
  setUsagePieData = (value) => {
    this.setState({ usagePieData: value });
  };
  componentDidMount() {
    this.setState({
      usageTimer: setInterval(() => {
        axios.get(cpuUsageEndpoint, { withCredentials: true }).then((res) => {
          let copy = [...this.state.usagePieData];
          if (copy.length >= 10) {
            copy.shift();
          }
          copy.push({ time: Date.now(), value: res.data.value });
          console.log(copy);
          this.setUsagePieData(copy);
        });
      }, 5000),
    });
  }
  componentWillUnmount() {
    clearInterval(this.state.usageTimer);
  }
  render() {
    if (!isSysAdmin()) {
      return <NotAllowed />;
    }
    return (
      <div className="container">
        <div className="row">
          <div className="col s12 m6" style={{ height: "300px" }}>
            <h3>CPU Usage</h3>
            <HorizontalLine
              data={this.state.usagePieData}
              xLabel="Time"
              yLabel="usage (%)"
            />
            <button
              className="btn red white-text"
              onClick={() => clearInterval(this.state.usageTimer)}
            >
              Stop
            </button>
          </div>
          <div className="col s12 m6" style={{ height: "300px" }}>
            <h3>RAM Usage</h3>
            <PieChartPadding data={[]} />
            <button className="btn red white-text">Stop</button>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
