import { React, Component } from "react";
import { Link as LinkRouter } from "react-router-dom";

export default class Tools extends Component {
  // constructor(props){
  //     super(props);
  // }

  render() {
    return (
      <div className="container">
        <LinkRouter className="btn-floating blue white-text left" to="/">
          <i className="material-icons">arrow_back</i>
        </LinkRouter>
        <h3 className="center green-text">Tools</h3>
        <p>WIP</p>
        <LinkRouter className="btn blue" to="/nbc_tool">
          NBC Generator
        </LinkRouter>
        <LinkRouter className="btn blue" to="/mrl_tool">
          AoS Map Rotation list generator
        </LinkRouter>
      </div>
    );
  }
}
