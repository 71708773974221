import { React, Component } from "react";
import { Link as LinkRouter, Outlet } from "react-router-dom";
import M from "materialize-css";
import { isStaff } from "../../Auth";
import { ValidateIPaddress } from "../Utilities";
import { axiosClient } from "../../generic/Axios";

class TemporalPush extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delay: props.delay || 200,
      progress: props.progress || 100,
    };
  }
  decrease = () => {
    if (this.state.progress > 0) {
      this.setState(
        (prevState) => ({
          progress: prevState.progress - 1,
        }),
        () => {
          setTimeout(this.decrease, this.state.delay);
        }
      );
    }
  };
  componentDidMount() {
    setTimeout(this.decrease, this.state.delay);
  }
  render() {
    const colorClass =
      "row card-panel " + this.props.color || "red darken-4 white-text";
    if (this.state.progress > 0) {
      return (
        <div className={colorClass}>
          {this.props.children}
          <div class="progress">
            <div
              class="determinate white"
              style={{ width: `${this.state.progress}%` }}
            ></div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }
}
class Alias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ip: props.ip,
      names: props.names,
    };
  }
  render() {
    return (
      <div className="card-panel indigo white-text">
        <pre>{this.state.ip}</pre>
        <pre>{this.state.names}</pre>
      </div>
    );
  }
}
export class Aliases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isIP: false,
      ip: "",
      textParams: "",
      isLoading: false,
      data: null,
      badRequest: false,
      noData: false,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      M.AutoInit();
    }, 50);
  }
  fetch = () => {
    this.setState({
      isLoading: true,
      noData: false,
      badRequest: false,
    });
    const searchParams = new URLSearchParams();
    searchParams.append("isIP", this.state.isIP);
    if (this.state.isIP) {
      const ips = this.state.ip.split(".");
      searchParams.append("ip1", ips[0]);
      searchParams.append("ip2", ips[1]);
      searchParams.append("ip3", ips[2]);
      searchParams.append("ip4", ips[3]);
    } else {
      searchParams.append("q", this.state.textParams);
    }
    axiosClient
      .get("/aliases/?" + searchParams.toString())
      .then((response) => {
        // console.log(response.data);
        if (response.data.data.length > 0) {
          this.setState({
            data: response.data.data,
            isLoading: false,
          });
        } else {
          this.setState({
            data: null,
            isLoading: false,
            noData: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          data: null,
          isLoading: false,
          badRequest: true,
        });
      });
  };
  validateIP = () => {
    const ips = this.state.ip.split(".");
    if (ips.length !== 4 || ips === ["*", "*", "*", "*"]) {
      return false;
    }
    for (let index = 0; index < ips.length; index++) {
      const val = ips[index];
      const n = parseInt(val);
      if (val === "" || (isNaN(n) && val !== "*") || n < 0 || n > 255) {
        return false;
      }
    }
    // if (!ValidateIPaddress(this.state.ip)) {
    //   return false;
    // }
    return true;
  };
  validateText = () => {
    const text = this.state.textParams.trim();
    if (text.length === 0 || text.length > 15) {
      return false;
    }
    return true;
  };
  validate = () => {
    if (this.state.isIP) {
      if (!this.validateIP()) {
        return false;
      }
    } else {
      if (!this.validateText()) {
        return false;
      }
    }

    this.fetch();
  };
  toggleCheck = () => {
    this.setState((prevState) => ({
      isIP: !prevState.isIP,
    }));
  };
  handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text/plain");
    const ips = text.split(".");
    if (ips.length !== 4 || ips === ["*", "*", "*", "*"]) {
      return alert("Clipboard has an invalid IP");
    }
    for (let index = 0; index < ips.length; index++) {
      const val = ips[index];
      const n = parseInt(val);
      if ((isNaN(n) && val !== "*") || n < 0 || n > 255) {
        return alert("Clipboard has an invalid IP");
      }
    }
    this.setState({
      ip: text,
    });
  };
  render() {
    const searchClass =
      this.validateIP() || this.validateText() ? "btn" : "btn disabled";
    return (
      <div className="row">
        <h5 className="center">Aliases</h5>
        {this.state.isLoading && (
          <div className="center">
            <div className="row"></div>
            <div className="preloader-wrapper big active">
              <div className="spinner-layer spinner-blue-only">
                <div className="circle-clipper left">
                  <div className="circle"></div>
                </div>
                <div className="gap-patch">
                  <div className="circle"></div>
                </div>
                <div className="circle-clipper right">
                  <div className="circle"></div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!this.state.data && this.state.badRequest && (
          <TemporalPush color="red darken-2 white-text">
            <p className="center">
              The server could not understand the request
            </p>
          </TemporalPush>
        )}
        {!this.state.data && this.state.noData && (
          <TemporalPush color="red darken-2 white-text">
            <p className="center">Nothing found</p>
          </TemporalPush>
        )}
        {this.state.data && (
          <div className="col s12">
            {this.state.data.map((a) => {
              return <Alias key={a} ip={a.ip} names={a.names} />;
            })}
          </div>
        )}
        <div className="col s12 m6 offset-m3">
          <p>
            <label>
              <input type="checkbox" onChange={this.toggleCheck} />
              <span>Search for IP?</span>
            </label>
          </p>
          {this.state.isIP && (
            <div class="row">
              <p>Use * for any ip in that range.</p>
              <div class="input-field col s12">
                <input
                  placeholder="192.168.1.5"
                  id="ip"
                  type="text"
                  class={this.validateIP() ? "valid" : "invalid"}
                  required
                  onPaste={this.handlePaste}
                  onChange={(e) => {
                    this.setState({ ip: e.target.value });
                  }}
                  value={this.state.ip}
                />
              </div>
            </div>
          )}
          {this.state.isIP && !this.validateIP() && (
            <div className="row card-panel red darken-4 white-text">
              <p>
                Invalid IP, must have 4 numbers in the range of 0-255 and
                between 3 dots each. Use * instead of numbers for range search.
              </p>
            </div>
          )}
          {this.state.isIP === false && (
            <div class="row">
              <p>
                Search for player name, min length required is 1 and max length
                is 15.
              </p>
              <div class="input-field col s12">
                <input
                  id="player-name"
                  type="text"
                  class={this.validateText() ? "valid" : "invalid"}
                  minLength={1}
                  maxLength={15}
                  required
                  onChange={(e) => {
                    this.setState({ textParams: e.target.value });
                  }}
                  value={this.state.textParams}
                />
                <label for="player-name">Player name</label>
              </div>
            </div>
          )}
          <div className="row center">
            <button className={searchClass} onClick={this.validate}>
              Search
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export class Echos extends Component {
  // constructor(props) {
  //     super(props);
  // }
  render() {
    return <div>Echos WIP</div>;
  }
}

class GeoIP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ip: props.ip,
      isp: props.isp,
      country: props.country,
      region: props.region,
      city: props.city,
      asn: props.asn,
      proxy: props.proxy,
    };
  }
  render() {
    return (
      <div className="card-panel indigo white-text">
        <pre className="center">{this.state.ip}</pre>
        <pre>ISP: {this.state.isp}</pre>
        <pre>Country: {this.state.country}</pre>
        <pre>State: {this.state.region}</pre>
        <pre>City: {this.state.city}</pre>
        <pre>ASN: {this.state.asn}</pre>
        <pre>Proxy: {this.state.proxy.toString()}</pre>
      </div>
    );
  }
}
export class GeoIPSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ipSearch: "",
      data: [],
      errors: [],
    };
  }
  componentDidMount() {
    setTimeout(() => {
      M.AutoInit();
    }, 50);
  }
  submit = () => {
    if (!this.validate()) {
      return alert("You provided an invalid IP");
    }
    this.fetch();
  };
  fetch() {
    this.setState({
      data: [],
      errors: [],
    });
    const ips = [...new Set(this.state.ipSearch.split(" "))];
    for (let index = 0; index < ips.length; index++) {
      const val = ips[index];
      axiosClient
        .get("/geoip/" + val)
        .then((response) => {
          if (Object.keys(response.data).length > 0) {
            this.setState((prevState) => ({
              data: [...prevState.data, response.data],
            }));
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState((prevState) => ({
            errors: [...prevState.errors, val],
          }));
        });
    }
  }
  validate = () => {
    const ips = [...new Set(this.state.ipSearch.split(" "))];
    if (ips.length === 0 || ips.length > 5) return false;
    for (let index = 0; index < ips.length; index++) {
      const val = ips[index];
      if (!ValidateIPaddress(val)) {
        return false;
      }
    }
    return true;
  };
  render() {
    return (
      <div className="row">
        <h5 className="center">GeoIP Search</h5>
        {this.state.data && (
          <div>
            {this.state.data.map((g) => {
              return <GeoIP key={g.ip} {...g} />;
            })}
          </div>
        )}
        {this.state.errors && (
          <div>
            {this.state.errors.map((e) => {
              return (
                <TemporalPush key={e}>
                  <p>Could not get a response for {e}</p>
                </TemporalPush>
              );
            })}
          </div>
        )}
        <div className="col s12">
          <p>Search a single or multiple IP separated by spaces, max 5 IP.</p>
          <div className="input-field">
            <input
              type="text"
              id="ip-search"
              className={this.validate() ? "valid" : "invalid"}
              required
              onChange={(e) => {
                this.setState({
                  ipSearch: e.target.value,
                });
              }}
            />
          </div>
          <button
            className={this.validate() ? "btn green" : "btn disabled"}
            onClick={this.submit}
          >
            Search
          </button>
        </div>
      </div>
    );
  }
}

export class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    setTimeout(() => {
      M.AutoInit();
    }, 50);
  }

  render() {
    if (!isStaff()) {
      window.location = "/login?next=features";
      return <></>;
    }
    return (
      <div className="container">
        <LinkRouter className="btn-floating blue white-text left" to="/">
          <i className="material-icons">arrow_back</i>
        </LinkRouter>
        <h3 className="center green-text">Features</h3>
        <p>WIP</p>
        <LinkRouter className="btn blue" to="/features/echos">
          Echos
        </LinkRouter>
        <LinkRouter className="btn blue" to="/features/aliases">
          Aliases
        </LinkRouter>
        <LinkRouter className="btn blue" to="/features/geoip">
          Geoip
        </LinkRouter>
        <LinkRouter className="btn blue" to="/features/mw">
          MW
        </LinkRouter>
        <Outlet />
      </div>
    );
  }
}
