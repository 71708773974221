import axios from "axios";
import { baseURL } from "../endpoints";
// axios instance
axios.defaults.withCredentials = true;

export const axiosClient = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  headers: { "Access-Control-Allow-Origin": "*" },
});
