const baseProt = "https://";
const baseDomain = "backend.aloha.pk";
const port = "5000";
const baseURL = `${baseProt}${baseDomain}:${port}/`;

const loginEndpoint = baseURL + "login";
const logoutEndpoint = baseURL + "logout";

const mwBySubmitters = baseURL + "mw-charts/submits";
const mwByHandled = baseURL + "mw-charts/handled";

module.exports = {
  baseURL,
  loginEndpoint,
  logoutEndpoint,
  mwBySubmitters,
  mwByHandled,
};
