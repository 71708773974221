import { React, Component } from "react";
// CSS
import AlohaWebSite from "../media/Aloha11Anniversary.png";
import AlohaStats from "../media/AlohaStatsPage.png";
// Components
import { Link as LinkRouter } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import { isLoggedIn } from "../Auth";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      loadingTimeout: null,
    };
  }

  componentDidMount() {
    this.setState({
      loadingTimeout: setTimeout(() => {
        this.setState({
          isLoading: false,
        });
      }, 1000),
    });
  }

  componentWillUnmount() {
    clearTimeout(this.state.loadingTimeout);
  }

  render() {
    if (this.state.isLoading)
      return (
        // <Fragment>
        <div className="container center">
          <LoadingSpinner />
        </div>
        // </Fragment>
      );

    return (
      // <Fragment>
      <div className="container">
        <h1 className="center">Backend Server</h1>
        <div className="row">
          <div className="col s12 m6">
            <div className="card">
              <div className="card-image">
                <img src={AlohaWebSite} alt="Aloha Website" />
                <a
                  href="https://aloha.pk/"
                  className="btn-floating halfway-fab waves-effect waves-light blue"
                >
                  <i className="material-icons">open_in_new</i>
                </a>
              </div>
              <div className="card-content grey darken-4 white-text">
                <span className="card-title grey darken-4">Aloha.pk Forum</span>
                <p>
                  We are aloha player killers, and friends! or aloha.pk for
                  short. We play, host, and moderate self-customized game
                  servers for moddable games.
                </p>
              </div>
            </div>
          </div>
          <div className="col s12 m6">
            <div className="card">
              <div className="card-image">
                <img src={AlohaStats} alt="Aloha Leaderboard Stats" />
                <a
                  href="https://stats.aloha.pk:1337/"
                  className="btn-floating halfway-fab waves-effect waves-light blue"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="material-icons">open_in_new</i>
                </a>
              </div>
              <div className="card-content grey darken-4 white-text">
                <span className="card-title grey darken-4">
                  Aloha.pk LeaderBoard
                </span>
                <p>
                  Aloha Server list and leaderboard track of all Aloha users.
                </p>
              </div>
            </div>
          </div>
        </div>
        {!isLoggedIn() && (
          <div className="row center">
            <LinkRouter
              className="btn-large blue white-text waves-effect waves-light"
              to="/login"
            >
              <i className="material-icons left">login</i>
              Login
            </LinkRouter>
          </div>
        )}
      </div>
      // </Fragment>
    );
  }
}

export default Home;
