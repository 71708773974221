import React, { Fragment, useEffect, useState, Component } from "react";
import { Outlet, Link as LinkRouter } from "react-router-dom";
// CSS
import "./Docs.css";
// Components
import M from "materialize-css";
import { Icon } from "react-materialize";
import { ReactSession } from "react-client-session";
import { isStaff, isSysAdmin } from "../../Auth";
import NotAllowed from "../NotAllowed";
// import ResponsiveNavBar from "../NavBar";
import { unEscape, copyToClipBoard } from "../../Utils";
import { jump } from "../../generic/jump";
import { axiosClient } from "../../generic/Axios";
import { BackTopButtonComponent } from "../BackTopButton";

function Command(props) {
  return (
    <div
      id={props.name}
      className="card-panel indigo darken-1 white-text hoverable command"
    >
      <h5 className="center white-text" style={{ margin: 0 }}>
        {props.name}
      </h5>
      <p>
        <b>Description:</b> {unEscape(props.description)}
      </p>
      {props.moreInfo && (
        <p>
          <b>More info:</b>
          {unEscape(props.moreInfo)}
        </p>
      )}
      <p>
        <b>Usage:</b>{" "}
        <button
          className="btn-floating btn-small grey lighten-2 right"
          onClick={() => copyToClipBoard("code-" + props.name)}
        >
          <Icon className="black-text">content_copy</Icon>
        </button>
        <code id={"code-" + props.name}>{unEscape(props.usage)}</code>
      </p>
      <p>
        <b>Channels available:</b> {props.channels.join(", ")}
      </p>
      <p>
        <b>Access:</b> {props.access}
      </p>
      <p>
        <b>Query:</b> {props.query.toString()}
      </p>
      <p>
        <b>Available in Discord:</b> {props.discord.toString()}
      </p>
    </div>
  );
}

function Commands() {
  const [commands, setCommands] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    // console.log(`${baseURL}command/`);
    axiosClient
      .get(`/command/`, {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response.data);
        if (response.data.data.length > 0) {
          setCommands(response.data.data);
          setIsLoading(false);
          setIsError(false);

          const elems = document.querySelectorAll(".autocomplete");
          const data = Object.assign(
            {},
            ...response.data.data.map((x) => ({ [x.name]: null }))
          );
          const options = {
            data: data,
            minLength: 2,
            onAutocomplete: function (cmd) {
              jump(cmd);
            },
          };
          M.Autocomplete.init(elems, options);
        }
      })
      .catch((error) => {
        setIsError(true);
      });
  }, []);
  if (!isStaff()) {
    window.location = "/login?next=commands";
    return <></>;
  }
  if (isError) {
    return (
      <div className="container" style={{ padding: "0 1em 1em 1em" }}>
        <LinkRouter className="btn-floating blue white-text left" to="/docs">
          <i className="material-icons">arrow_back</i>
        </LinkRouter>
        <h3 className="center green-text">Commands</h3>
        <div className="center">
          <h5>Woops, something went wrong. Try refreshing the page.</h5>
        </div>
      </div>
    );
  }
  return (
    <div className="container" style={{ padding: "0 1em 1em 1em" }}>
      <LinkRouter className="btn-floating blue white-text left" to="/docs">
        <i className="material-icons">arrow_back</i>
      </LinkRouter>
      <h3 className="center green-text">Commands</h3>
      {isLoading && (
        <div className="center">
          <div className="row"></div>
          <div className="preloader-wrapper big active">
            <div className="spinner-layer spinner-blue-only">
              <div className="circle-clipper left">
                <div className="circle"></div>
              </div>
              <div className="gap-patch">
                <div className="circle"></div>
              </div>
              <div className="circle-clipper right">
                <div className="circle"></div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div class="row">
        <div class="col s12">
          <div class="row">
            <div class="input-field col s12">
              <i class="material-icons prefix">search</i>
              <input type="text" id="command-search" class="autocomplete" />
              <label for="command-search">Search command</label>
            </div>
          </div>
        </div>
      </div>

      {commands &&
        commands.map((cmd) => {
          return (
            <button
              className="btn blue"
              key={"btn_" + cmd.name}
              onClick={() => jump(cmd.name)}
            >
              {cmd.name}
            </button>
          );
        })}

      {commands &&
        commands.map((cmd) => {
          return <Command key={cmd.name} {...cmd} />;
        })}
    </div>
  );
}

async function updateAoSCommand(cmd, description, usage) {
  try {
    const formData = new FormData();
    formData.append("description", description);
    formData.append("usage", usage);
    const res = await axiosClient.put("/aos_command/" + cmd, formData);
    if (res.status === 200) {
      return [true, "Success"];
    }
    return [false, "Success but no success"];
  } catch (err) {
    return [false, err.response.data.error];
  }
}

function AoSCommand(props) {
  const [editable, setEditable] = useState(false);

  // initial and after update states
  const [initialDescription, setInitialDescription] = useState(
    props.description
  );
  const [initialUsage, setInitialUsage] = useState(props.usage);
  // current state (when user is editing and reverting changes)
  const [description, setDescription] = useState(props.description);
  const [usage, setUsage] = useState(props.usage);

  const [updating, setUpdating] = useState(false);

  M.Tooltip.init(document.querySelectorAll(".tooltipped"));

  return (
    <div
      id={props.name}
      className="card-panel indigo darken-1 white-text hoverable command editable"
    >
      <h5 className="center white-text" style={{ margin: 0 }}>
        {props.name}
        {!editable && (
          <button
            className="btn-floating btn-small amber darken-3 right edit tooltipped show-on-small"
            data-position="top"
            data-tooltip="Edit info"
            onClick={() => {
              setDescription(initialDescription);
              setUsage(initialUsage);
              setEditable(true);

              setTimeout(() => {
                M.Tooltip.init(document.querySelectorAll(".tooltipped"));
                M.updateTextFields();
              }, 50);
            }}
          >
            <Icon className="white-text">edit</Icon>
          </button>
        )}
      </h5>
      {!editable && (
        <Fragment>
          <p>
            <b>Description:</b> {unEscape(description)}
          </p>
        </Fragment>
      )}
      {editable && (
        <div class="input-field col s12">
          <input
            id={props.name + "-description-input"}
            type="text"
            className="validate"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          <label for={props.name + "-description-input"}>Description</label>
        </div>
      )}
      {!editable && (
        <Fragment>
          <p>
            <b>Usage:</b>{" "}
            {usage && (
              <button
                className="btn-floating btn-small grey lighten-2 right"
                onClick={() => copyToClipBoard("code-" + props.name)}
              >
                <Icon className="black-text">content_copy</Icon>
              </button>
            )}
            <code id={"code-" + props.name}>{unEscape(usage)}</code>
          </p>
        </Fragment>
      )}
      {editable && (
        <div class="input-field col s12">
          <input
            id={props.name + "-usage-input"}
            type="text"
            className="validate"
            value={usage}
            onChange={(e) => {
              setUsage(e.target.value);
            }}
          />
          <label for={props.name + "-usage-input"}>Usage</label>
        </div>
      )}
      <p>
        <b>Access:</b> {props.access}
      </p>
      {editable && (
        <div className="row">
          <div className="col s12 m6 offset-m6 right-align">
            <button
              className="btn btn-small grey darken-1 tooltipped"
              data-position="top"
              data-tooltip="Revert changes"
              style={{ marginRight: "0.5em" }}
              onClick={() => {
                setDescription(initialDescription);
                setUsage(initialUsage);
                setEditable(false);
              }}
            >
              <Icon className="white-text">cancel</Icon>
            </button>
            <button
              className="btn btn-small green tooltipped"
              data-position="top"
              data-tooltip="Save changes"
              onClick={async () => {
                setUpdating(true);
                const [status, message] = await updateAoSCommand(
                  props.name,
                  description,
                  usage
                );
                if (status) {
                  setEditable(false);
                  setInitialDescription(description);
                  setInitialUsage(usage);
                } else {
                  setDescription(initialDescription);
                  setUsage(initialUsage);
                  M.updateTextFields();
                  alert(message);
                }
                setUpdating(false);
              }}
            >
              <Icon className="white-text">done</Icon>
            </button>
          </div>
        </div>
      )}

      {updating && (
        <div class="progress">
          <div class="indeterminate"></div>
        </div>
      )}
    </div>
  );
}

function AosCommands() {
  const [commands, setCommands] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    // console.log(`${baseURL}command/`);
    axiosClient
      .get(`/aos_command/`, {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response.data);
        if (response.data.data.length > 0) {
          setCommands(response.data.data);
          setIsLoading(false);
          setIsError(false);

          const elems = document.querySelectorAll(".autocomplete");
          const data = Object.assign(
            {},
            ...response.data.data.map((x) => ({ [x.name]: null }))
          );
          const options = {
            data: data,
            minLength: 2,
            onAutocomplete: function (cmd) {
              jump(cmd);
            },
          };
          M.Autocomplete.init(elems, options);
        } else {
          setIsLoading(false);
          setIsError(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setIsError(true);
      });
  }, []);
  if (!isStaff()) {
    window.location = "/login?next=/docs/aoscommands";
    return <></>;
  }
  if (isError) {
    return (
      <div className="container" style={{ padding: "0 1em 1em 1em" }}>
        <LinkRouter className="btn-floating blue white-text left" to="/docs">
          <i className="material-icons">arrow_back</i>
        </LinkRouter>
        <h3 className="center green-text">AoS Commands</h3>
        <div className="center">
          <h5>Woops, something went wrong</h5>
        </div>
      </div>
    );
  }
  return (
    <div className="container">
      <LinkRouter className="btn-floating blue white-text left" to="/docs">
        <i className="material-icons">arrow_back</i>
      </LinkRouter>
      <h3 className="center green-text">Aos Commands</h3>
      {isLoading && (
        <div className="center">
          <div className="row"></div>
          <div className="preloader-wrapper big active">
            <div className="spinner-layer spinner-blue-only">
              <div className="circle-clipper left">
                <div className="circle"></div>
              </div>
              <div className="gap-patch">
                <div className="circle"></div>
              </div>
              <div className="circle-clipper right">
                <div className="circle"></div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div class="row">
        <div class="col s12">
          <div class="row">
            <div class="input-field col s12">
              <i class="material-icons prefix">search</i>
              <input type="text" id="command-search" class="autocomplete" />
              <label for="command-search">Search command</label>
            </div>
          </div>
        </div>
      </div>

      {commands &&
        commands.map((cmd) => {
          return (
            <button
              className="btn blue"
              key={"btn_" + cmd.name}
              onClick={() => jump(cmd.name)}
            >
              {cmd.name}
            </button>
          );
        })}

      {commands &&
        commands.map((cmd) => {
          return <AoSCommand key={cmd.id} {...cmd} />;
        })}
    </div>
  );
}

function SysCommands() {
  if (!isSysAdmin()) {
    return <NotAllowed />;
  }
  return (
    <div className="container">
      <LinkRouter className="btn-floating blue white-text left" to="/docs">
        <i className="material-icons">arrow_back</i>
      </LinkRouter>
      <h3 className="center green-text">Sysadmin commands</h3>
      <p>WIP</p>
    </div>
  );
}

function API() {
  return (
    <div className="container">
      <LinkRouter className="btn-floating blue white-text left" to="/docs">
        <i className="material-icons">arrow_back</i>
      </LinkRouter>
      <h3 className="center green-text">API</h3>
      <p>WIP</p>
    </div>
  );
}

class DocumentationLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="container">
        <h1 className="center">CheckBot</h1>
        <h5 className="center">The bot that you love</h5>
        <div className="row">
          <p>Hello {ReactSession.get("username")}</p>
        </div>
        <Outlet />
        <BackTopButtonComponent />
      </div>
    );
  }
}

class Documentation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    setTimeout(() => {
      M.AutoInit();
    }, 50);
  }

  render() {
    if (!isStaff()) {
      window.location = "/login?next=docs";
      return <></>;
    }
    return (
      <div className="row">
        <div className="col s12 m6 l4">
          <div className="card grey darken-3">
            <div className="card-content white-text">
              <span className="card-title">Commands</span>
              <p>Commands are CheckBot general commands, like !check</p>
            </div>
            <div className="card-action">
              <LinkRouter className="btn blue" to="/docs/commands">
                Commands
              </LinkRouter>
            </div>
          </div>
        </div>
        <div className="col s12 m6 l4">
          <div className="card grey darken-3">
            <div className="card-content white-text">
              <span className="card-title">Emoji Commands</span>
              <p>
                React to message with emojis that triggers specific commands
              </p>
            </div>
            <div className="card-action">
              <LinkRouter className="btn blue" to="/docs/emojis">
                Emojis
              </LinkRouter>
            </div>
          </div>
        </div>
        <div className="col s12 m6 l4">
          <div className="card grey darken-3">
            <div className="card-content white-text">
              <span className="card-title">AoS Commands</span>
              <p>
                AoS commands are specific for Aloha's Ace of Spades servers,
                just like babel_aloha!server
              </p>
            </div>
            <div className="card-action">
              <LinkRouter className="btn blue" to="/docs/aoscommands">
                Aos Commands
              </LinkRouter>
            </div>
          </div>
        </div>
        {isSysAdmin() && (
          <div className="col s12 m6 l4">
            <div className="card grey darken-3">
              <div className="card-content white-text">
                <span className="card-title">Sysadmin Commands</span>
                <p>
                  Restricted commands for sysadmins+ due to the impact on the
                  bot access
                </p>
              </div>
              <div className="card-action">
                <LinkRouter className="btn blue" to="/docs/syscommands">
                  Sysadmin commands
                </LinkRouter>
              </div>
            </div>
          </div>
        )}
        <div className="col s12 m6 l4">
          <div className="card grey darken-3">
            <div className="card-content white-text">
              <span className="card-title">API</span>
              <p>API is Checkbot's internal documentation</p>
            </div>
            <div className="card-action">
              <LinkRouter className="btn blue" to="/docs/api">
                API
              </LinkRouter>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export {
  DocumentationLayout,
  Documentation,
  Commands,
  AosCommands,
  SysCommands,
  API,
};
