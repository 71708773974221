import { React } from "react";

export default function NotAllowed() {
  setTimeout(() => {
    window.location = "/login";
  }, 1000);
  return (
    <div className="container center">
      <h1>Not allowed</h1>
      <p>You are being redirected...</p>
    </div>
  );
}
