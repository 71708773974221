import { React, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
// CSS
import "./css/Login.css";
// Components
import { Icon, Button, TextInput } from "react-materialize";
import LoadingSpinner from "./components/LoadingSpinner";
import { isLoggedIn, setCredentials } from "./Auth";
// login endpoint
import { loginEndpoint } from "./endpoints";

export default function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!userName || !password) {
      return;
    }

    const data = new FormData();
    data.append("username", userName);
    data.append("password", password);
    setIsLoading(true);
    setIsError(false);
    setErrorMsg("");
    axios
      .post(loginEndpoint, data)
      .then((res) => {
        if (res.status === 200) {
          setCredentials(userName, res.data.status, res.data.roles);
          // navigate("/");
          const search = window.location.search;
          const nextPage = new URLSearchParams(search).get("next");
          if (nextPage) {
            window.location = nextPage;
          } else {
            window.location = "/";
          }
        } else {
          setIsError(true);
          setErrorMsg(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsError(true);
        setErrorMsg(
          error.response?.data.message ||
            "Woops, something went wrong. Try again later"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoggedIn()) {
    return <Navigate replace to="/" />;
  }

  return (
    <div className="container">
      <div className="row center">
        <h3>Sign in using your Aloha's username and password</h3>
      </div>
      <div className="container center a-grey white-text z-depth-5">
        <div className="row">
          <br />
          <br />
          <form onSubmit={handleSubmit} className="col s12">
            <div className="row">
              <TextInput
                validate={true}
                icon={<Icon>email</Icon>}
                id="username"
                label="Username"
                name="username"
                s={12}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
              />
            </div>
            <div className="row">
              <TextInput
                validate={true}
                icon={<Icon>vpn_key</Icon>}
                id="password"
                label="Password"
                name="password"
                password
                s={12}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
            <div className="row">
              <Button
                node="button"
                type="submit"
                waves="light"
                className="btn-large blue"
                disabled={isLoading}
              >
                Login
                <Icon left>login</Icon>
              </Button>
            </div>
          </form>
        </div>

        {isLoading && <LoadingSpinner color="green" />}
        {!isLoading && isError && (
          <div className="row">
            <h3>{errorMsg}</h3>
          </div>
        )}
      </div>
    </div>
  );
}
